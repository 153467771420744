import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const DatasetExplanationPage = () => {
  return (
    <div className="bg-white min-h-screen text-black space-y-8">
      <header className="bg-blue-900 text-white p-4">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link
            to="/"
            className="flex items-center px-3 py-2 rounded hover:bg-blue-700 transition-colors"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Home
          </Link>
          <h1 className="text-2xl font-bold ml-4">REDDIT TOPICS</h1>
        </div>
      </header>

      <main className="max-w-4xl mx-auto p-8 space-y-20">
        <article className="prose lg:prose-xl mx-auto">
          <h1 className="text-5xl font-semibold mb-8 text-center text-blue-900">How Our Reddit Topics Dataset Was Created</h1>
          
          <section className="flex flex-col lg:flex-row items-center lg:items-start mb-20">
            <div className="lg:w-3/4 lg:pr-8">
              <h2 className="text-3xl font-semibold mb-4 text-blue-800">Introduction</h2>
              <p>We began our analysis using the <a href="https://pushshift.io/signup" target="_blank" className="text-blue-500">Pushshift dataset</a>, focusing exclusively on posts rather than comments. The dataset initially contained 1.7 billion posts, but after applying various steps in our pipeline, it was reduced to 43 million.</p><br />
              <p>The original dataset is unstructured by nature, as it's composed entirely of text, thus it wouldn't be analyzable in its original format. By categorizing each post into a set of predefined topics, we can simplify the analysis and make the data more manageable.</p><br />
              <p>This approach goes beyond just dividing content by subreddits. In large subreddits like r/funny or r/gaming, posts can cover many topics, making the subreddit itself less informative. Additionally, some topics are discussed across multiple subreddits. This is why our method is more effective for categorizing and summarizing content. </p>
            </div>
            <img src="/posts.png" alt="BERTopic Process" className="my-8 rounded-lg shadow-md lg:w-1/4 lg:ml-8" />
          </section>

          <section className="mb-20">
            <h2 className="text-3xl font-semibold mb-4 text-blue-800">Topic Modeling with BERTopic</h2>
            <p>The  <a href="https://maartengr.github.io/BERTopic/index.html" target="_blank" className="text-blue-500">BERTopic</a> process included the following steps:</p><br />
            <ol className="list-disc pl-6">
              <li><strong>Embedding creation using a pre-trained language model</strong> (we used BERT). This generates a vector that captures the meaning of each post.</li>
              <li><strong>Dimensionality reduction with UMAP</strong>: Since clustering high-dimensional vectors (300 dimensions) is inefficient, we reduced the dimensions to 5 using UMAP.</li>
              <li><strong>Clustering with HDBSCAN</strong>: A density-based algorithm that either assigns data points to clusters or labels them as noise.</li>
              <li><strong>Topic representation using c-TF-IDF</strong>: Posts within a cluster are treated as a single document. Applying TF-IDF reveals the most important words in each cluster, based on their frequency within the cluster compared to other clusters.</li>
              <li><strong>Topic naming using ChatGPT</strong>: The final output is a list of representative words for each topic, which we input into ChatGPT to generate more understandable topic names.</li>
            </ol>
            <img src="/pipeline.png" alt="BERTopic Process" className="my-8 rounded-lg shadow-md" />
          </section>

          <section className="mb-20">
            <h2 className="text-3xl font-semibold mb-4 text-blue-800">Data Processing Challenges</h2>
            <p>Processing the massive Reddit dataset (approximately 1.7 billion posts) presented several challenges:</p>
            <ul className="list-disc pl-6">
              <li>Memory management for large-scale data</li>
              <li>Computational time optimization</li>
            </ul>
            <p>To overcome these challenges, we used GPU-accelerated processing for UMAP and HDBSCAN, and developed a strategy to fit the model on a representative subset of the data.</p>
          </section>
          <section className="mb-20">
            <h2 className="text-3xl font-semibold mb-4 text-blue-800">Acknowledgments</h2>
            <p>This dataset was created during my summer internship at ETH Zurich in the <a href="https://coss.ethz.ch/" target="_blank" className="text-blue-500">Computational Social Science Lab</a> under the supervision of <a href="https://www.linkedin.com/in/andrea-musso-a12259233/" target="_blank" className="text-blue-500">Andrea Musso</a>. I am grateful for the opportunity to contribute to this research and for the guidance provided throughout the project.</p>
          </section>
        </article>
      </main>
    </div>
  );
};

export default DatasetExplanationPage;