import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Plot from 'react-plotly.js';
import debounce from 'lodash/debounce';
import { Search } from 'lucide-react';

const TimeSeriesPlot = () => {
  const [data, setData] = useState({});
  const [selectedTopics, setSelectedTopics] = useState(['celebration']);
  const [topics, setTopics] = useState([]);
  const [searchMode, setSearchMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  const fetchData = useCallback(async (topic) => {
    const baseUrl = process.env.REACT_APP_API_URL || '';
    const response = await fetch(`${baseUrl}/api/time_series?topic=${topic}`);
    const jsonData = await response.json();
    return jsonData;
  }, []);

  useEffect(() => {
    const fetchTopics = async () => {
      const baseUrl = process.env.REACT_APP_API_URL || '';
      const response = await fetch(`${baseUrl}/api/topics`);
      const topicsData = await response.json();
      setTopics(topicsData);
    };
    
    fetchTopics();
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      const newData = {};
      for (const topic of selectedTopics) {
        newData[topic] = await fetchData(topic);
      }
      setData(newData);
    };

    fetchAllData();
  }, [selectedTopics, fetchData]);

  const handleTopicChange = (topic) => {
    setSelectedTopics(prev => {
      if (prev.includes(topic)) {
        return prev.filter(t => t !== topic);
      } else if (prev.length < 3) {
        return [...prev, topic];
      } else {
        return prev;
      }
    });
  };

  const colors = ['red', 'green', 'orange', 'purple', 'cyan', 'magenta', 'lime'];

  const plotData = selectedTopics.map((topic, index) => ({
    x: data[topic]?.map(item => item.month) || [],
    y: data[topic]?.map(item => item.percentage ) || [],
    type: 'scatter',
    mode: 'lines',
    line: { color: colors[index % colors.length], width: 2 },
    name: topic,
    showlegend: false,
  }));

  function getTickFormat(value) {
    if (value < 0.01) {
      return '.3%'; // Display with 3 decimal places for small numbers
    } else if (value < 0.1) {
      return '.2%'; // Display with 2 decimal places for medium numbers
    } else {
      return '.1%'; // Display with 1 decimal place for larger numbers
    }
  }

  const layout = {
    title: {
      text: 'Percentage of Posts by Topic (Relative to Total)',  // Add your desired plot title here
      font: { size: 18 },  // Adjust the font size for the title
      x: 0.5,  // Center the title horizontally
      xanchor: 'center',  // Anchor it in the middle
    },
    xaxis: { 
      title: {
        text: 'Month',
        font: { size: 14 },
      },
      gridcolor: '#e0e0e0', 
      zeroline: false 
    },
    yaxis: { 
      tickformat: '.1%',  // Display numbers as integers
      gridcolor: '#e0e0e0', 
      zeroline: false,
      titlefont: { size: 14 },
      automargin: true,
    },
    plot_bgcolor: 'white',
    paper_bgcolor: 'white',
    font: { color: 'black' },
    showlegend: false,
    margin: { l: 10, r: 5, t: 30, b: 40 },
    autosize: true,
  };

  // Handle resizing to detect if it's a mobile screen
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const CustomCheckbox = ({ color, ...props }) => (
    <input
      type="checkbox"
      className="mr-2 appearance-none w-4 h-4 border-2 border-white rounded-sm checked:bg-current"
      style={{ color }}
      {...props}
    />
  );

  const filteredTopics = topics.filter(topic => 
    !selectedTopics.includes(topic) && 
    topic.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex flex-col lg:flex-row bg-gray-100 p-2 md:p-4 gap-4" style={{ minHeight: isMobile ? '900px' : '50vh', maxHeight: isMobile ? '900px' : '50vh',  }}>
      <div className="w-full lg:w-1/3 flex flex-col gap-4 overflow-hidden" style={{ flexShrink: 0 }}>
        <div className="bg-blue-900 rounded-lg p-3 md:p-4" style={{ minHeight: '30%', maxHeight: '30%' }}>
          <h3 className="text-xl md:text-2xl text-white font-bold mb-2">SELECTED TOPICS</h3>
          <div className="flex flex-col gap-2 overflow-y-auto" style={{ maxHeight: '130px', minHeight: '130px' }}>
            {selectedTopics.map((topic, index) => (
              <div key={topic} className="flex items-center gap-2">
                <label className="flex items-center text-white flex-1 overflow-hidden">
                  <CustomCheckbox
                    color={colors[index % colors.length]}
                    checked={true}
                    onChange={() => handleTopicChange(topic)}
                  />
                  <span className="text-sm truncate">{topic.toUpperCase()}</span>
                </label>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-blue-900 rounded-lg p-3 md:p-4 flex flex-col flex-grow">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-xl md:text-2xl text-white font-bold flex-grow">

              <input
              type="text"
              placeholder="Search topics..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-white text-black px-2 py-1 rounded w-full"
            />

          </h3>
          <Search
            className="text-blue-900 bg-white rounded-lg p-1 md:p-2 cursor-pointer ml-2 w-8 h-8 md:w-10 md:h-10 flex items-center justify-center"
            onClick={() => setSearchMode(!searchMode)}
            size={20}
          />
        </div>
        
        <div className="flex flex-col gap-2 overflow-y-auto flex-grow" style={{ maxHeight: isMobile ? '150px' : '250px', minHeight: isMobile ? '150px' : '250px'}}>
          {filteredTopics.map(topic => (
            <label key={topic} className="flex items-center text-white">
              <CustomCheckbox
                color="white"
                checked={false}
                onChange={() => handleTopicChange(topic)}
              />
              <span className="text-sm">{topic.toUpperCase()}</span>
            </label>
          ))}
        </div>
      </div>




    </div>
    <div className="w-full lg:w-2/3 bg-white rounded-lg p-2 md:p-4 flex-grow" style={{ minHeight: '300px', maxHeight: '60vh' }}>
  <Plot
    data={plotData}
    layout={{
      ...layout,
      dragmode: !isMobile , 
      scrollzoom: !isMobile // Ensure scroll zooming is also disabled
    }}
    config={{ 
      displayModeBar: !isMobile // Hide the mode bar
    }}
    style={{ width: '100%', height: '100%' }}
    useResizeHandler={false} // Disable resizing
    onClick={(data) => {
      if (data.points && data.points.length > 0) {
        const point = data.points[0];
        // Handle click event here, e.g., display point information
        console.log(point);
      }
    }}
  />
</div>
  </div>

  );
};

export default TimeSeriesPlot;
