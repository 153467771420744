import React, { useState } from 'react';
import { Download, Mail, HelpCircle, FileText, Menu } from 'lucide-react';
import TimeSeriesPlot from '../components/TimeSeriesPlot';
import TopicsInSubreddit from '../components/TopicsInSubreddit';
import { useNavigate } from 'react-router-dom';

const RedditProjectPage = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const NavButton = ({ icon: Icon, text, onClick }) => (
    <button
      className="flex items-center px-3 py-2 rounded hover:bg-blue-700 transition-colors w-full sm:w-auto"
      onClick={onClick}
    >
      <Icon className="w-5 h-5 mr-2" />
      {text}
    </button>
  );

  return (
    <div className="bg-white min-h-screen text-black">
    <header className="bg-blue-900 text-white p-4">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">REDDIT TOPICS</h1>
          <button
            className="sm:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Menu className="w-6 h-6" />
          </button>
          <nav className={`${isMenuOpen ? 'block' : 'hidden'} sm:block mt-0`}>
            <div className="flex flex-col sm:flex-row sm:justify-end space-y-2 sm:space-y-0 sm:space-x-4">
              <NavButton
                icon={Download}
                text="Download"
                onClick={() => window.location.href = 'https://www.kaggle.com/datasets/stefano1283/reddit-topic-dataset/data?select=cluster_and_topic_names.parquet'}
              />
              <NavButton
                icon={Mail}
                text="Contact Us"
                onClick={() => navigate('/contact')}
              />
              <NavButton
                icon={FileText}
                text="Dataset Info"
                onClick={() => navigate('/dataset-explanation')}
              />
            </div>
          </nav>
        </div>
      </div>
    </header>

    <main className="container mx-auto p-4 space-y-20">
      <section className="w-full">
        <h2 className="text-3xl font-semibold mb-4 text-center text-blue-900">Topic Trends</h2>
        <div className="w-full sm:w-[calc(100vw-2rem)] sm:max-w-full sm:mx-[-1rem]">
          <TimeSeriesPlot />
        </div>
      </section>

      {/* <section className="w-full">
        <h2 className="text-3xl font-semibold mb-4 text-center text-blue-900">Explore Subreddit Topics</h2>
        <div className="w-full sm:w-[calc(100vw-2rem)] sm:max-w-full sm:mx-[-1rem] bg-gray-100 p-4 rounded-lg">
          <TopicsInSubreddit />
        </div>
      </section> */}
    </main>
    </div>
  );
};

export default RedditProjectPage;