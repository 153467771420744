import React from 'react';
import { ArrowLeft, Mail, Linkedin } from 'lucide-react';
import { Link } from 'react-router-dom';

const ContactPage = () => {
  return (
    <div className="bg-white min-h-screen text-black space-y-8">
      <header className="bg-blue-900 text-white p-4">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link
            to="/"
            className="flex items-center px-3 py-2 rounded hover:bg-blue-700 transition-colors"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Home
          </Link>
          <h1 className="text-2xl font-bold ml-4">REDDIT PROJECT</h1>
        </div>
      </header>

      <main className="max-w-7xl mx-auto p-8 space-y-20">
        <section className="w-full">
          <h2 className="text-4xl font-semibold mb-8 text-center text-blue-900">CONTACT US</h2>
          <div className="w-full max-w-2xl mx-auto bg-gray-100 p-8 rounded-lg">
            <p className="text-lg mb-6">
              Feel free to reach out to me for any question or feedback. 
            </p>
            <div className="space-y-4">
              <div className="flex items-center">
                <Mail className="w-6 h-6 mr-3 text-blue-900" />
                <a href="mailto:stefano.viel@epfl.ch" className="text-blue-600 hover:underline">
                  viel.stefano01 [at] gmail.com
                </a>
              </div>
              <div className="flex items-center">
                <Linkedin className="w-6 h-6 mr-3 text-blue-900" />
                <a href="https://www.linkedin.com/in/stefano-viel-2ba509184/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                  LinkedIn Profile
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContactPage;